import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LOGO from "../../assets/images/icons/logo-blue.png"
import SEARCH from "../../assets/images/icons/search.png"
import CLOSE from "../../assets/images/icons/close.png"
import HAMBURGER from "../../assets/images/icons/hamburger.png"


export default function Header() {
  const [show, setShow] = useState(false);

  const toggleDropdown = () => {
    setShow(!show);
  };
  return (
    <div className="headerBase">
      <div className="headerContent">
        <div className="headerFlex">
          <Link
            to="/home"
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
            style={{ textDecoration: "none", height: "100%" }}
          >
            <img
              src={LOGO}
              alt="logo"
              className="logoImg"
            />
          </Link>
        </div>
        <div className="headerFlex">
          <div className="navTxtDiv">
            <Link to="/home" style={{ textDecoration: "none" }}>
              <p className="navTxt">Home</p>
            </Link>
          </div>
          <div className="navTxtDiv">
            <Link to="/product" style={{ textDecoration: "none" }}>
              <p className="navTxt">Products</p>
            </Link>
          </div>
          <div className="navTxtDiv">
            <HashLink
              smooth
              style={{ textDecoration: "none" }}
              to="/home#gallery"
            >
              <p className="navTxt">Gallery</p>
            </HashLink>
          </div>
          <div className="navTxtDiv">
            <HashLink
              smooth
              style={{ textDecoration: "none" }}
              to="/home#about"
            >
              <p className="navTxt">About Us</p>
            </HashLink>
          </div>
          <div className="navTxtDiv">
            <HashLink
              smooth
              style={{ textDecoration: "none" }}
              to="/home#contact"
            >
              <p className="navTxt">Contact Us</p>
            </HashLink>
          </div>
          <div className="navTxtDiv">
            <a href="tel:+1 (832) 908-5706" style={{ textDecoration: "none" }}>
              <p className="navTxt">+1 (832) 908-5706</p>
            </a>
          </div>
          <div className="navBtnDiv">
            <input type="text" className="searchBtn" placeholder="Search..." />
            <img
              src={SEARCH}
              alt="search icon"
              className="searchIcon"
            />
          </div>
        </div>
      </div>

      <div className="respHeaderContent">
        <div className="repHeaderFlex">
          <img
            src={LOGO}
            alt="logo"
            className="logoImgResp"
          />
        </div>
        <div className="repHeaderFlex">
          <button onClick={() => toggleDropdown()} className="btnDiv">
            {show ? (
              <img
                src={CLOSE}
                alt="logo"
                className="respIcon"
              />
            ) : (
              <img
                src={HAMBURGER}
                alt="logo"
                className="respIcon"
              />
            )}
          </button>
        </div>
      </div>
      {show && (
        <div className="headerDropdown">
          <div className="navTxtResp">
            <Link
              to="/home"
              onClick={() => toggleDropdown()}
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">Home</p>
            </Link>
          </div>
          <div className="navTxtResp">
            <Link
              to="/product"
              onClick={() => toggleDropdown()}
              style={{ textDecoration: "none" }}
            >
              <p className="navTxt">Products</p>
            </Link>
          </div>
          <div className="navTxtResp">
            <HashLink
              smooth
              onClick={() => toggleDropdown()}
              style={{ textDecoration: "none" }}
              to="/home#gallery"
            >
              <p className="navTxt">Gallery</p>
            </HashLink>
          </div>
          <div className="navTxtResp">
            <HashLink
              onClick={() => toggleDropdown()}
              smooth
              style={{ textDecoration: "none" }}
              to="/home#about"
            >
              <p className="navTxt">About Us</p>
            </HashLink>
          </div>
          <div className="navTxtResp">
            <HashLink
              onClick={() => toggleDropdown()}
              smooth
              style={{ textDecoration: "none" }}
              to="/home#contact"
            >
              <p className="navTxt">Contact Us</p>
            </HashLink>
          </div>
          <div className="navTxtResp">
            <p className="navTxt">+1 1234 567 890</p>
          </div>
          <div className="navBtnDiv">
            <input type="text" className="searchBtn" placeholder="Search..." />
            <img
              src={SEARCH}
              alt="search icon"
              className="searchIcon"
            />
          </div>
        </div>
      )}
    </div>
  );
}
