import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.scss";
import { array } from "../../assets/data/carousel";
import { HashLink } from "react-router-hash-link";
import LOG from "../../assets/images/icons/logo.png"
import DOWN from "../../assets/images/icons/down.png"

export default function CarouselSession() {
  return (
    <div>
      <Carousel
        autoPlay={true}
        dynamicHeight={true}
        infiniteLoop={true}
        showArrows={true}
        showStatus={false}
        showIndicators={true}
        useKeyboardArrows={true}
        swipeable={true}
        emulateTouch={true}
        showThumbs={false}
        interval={3000}
      >
        {array.map((data) => (
          <div className="carCardBase" key={data.id}>
            <img className="carImg" src={data.img} alt="img1" />
            <div className="carDataDiv">
              <div className="carContent">
                <div className="carTopContent">
                  <div className="carLogo">
                    <img
                      src={LOG}
                      alt="logo"
                    />
                  </div>
                  <div className="carTitle">{data.title}</div>
                </div>
                <div className="carButton">
                  <HashLink
                    smooth
                    style={{ textDecoration: "none" }}
                    to="/home#about"
                  >
                    <div className="carBtnArrow">
                      <img src={DOWN} alt="arrow" className="arrowImg" />
                    </div>
                  </HashLink>
                  <div className="carBtnTxt">
                    EXPLORE OUR RANGE OF QUALITY PRODUCTS
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* <div className="carCardBase">
          <img
            className="carImg"
            src="https://media.istockphoto.com/id/1281634294/photo/green-door-surrounded-by-green-ivy.jpg?s=612x612&w=0&k=20&c=VvmqLz2JmgXvhZfAsG4yYOuEP1edLksAzQ0FkRS2kcQ="
            alt="img1"
          />
          <div className="carDataDiv">
            <div className="carContent">
              <div className="carTopContent">
                <div className="carLogo"></div>
                <div className="carTitle">Custom Desdffgdf</div>
              </div>
              <div className="carButton">
                <div className="carBtnArrow">
                  <img src={Arrow} alt="arrow" />
                  <div className="carBtnTxt">
                    EXPLORE OUR RANGE OF QUALITY PRODUCTS
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Carousel>
    </div>
  );
}
