import React from "react";
import "./Testimony.scss";
import Star from "../../assets/star.png";

export default function Testimony() {
  return (
    <div className="testimonyBase">
      <div className="testimonyContent">
        <div className="testimonyTop">
          <p className="testimonyTitle">Our Testimonial</p>
          <p className="testimonySubTitle">What Our Customers Have To Say</p>
        </div>
        <div className="testimonyGrid">
          <div className="testimonyDataBase">
            <div className="testimonyData">
              <p className="testDespTxt">
                "Very happy with the whole process of getting my new front door
                installed,great communication,excellent workmanship and
                delighted with the price we paid. Wish we had discovered Buy
                Your Own Doors earlier!"
              </p>
              <div>
                <div className="starImgDiv">
                  <img src={Star} alt="star" className="starImg" />
                </div>
                <p className="testNameTxt">Thomas Theodore</p>
                <p className="testLocationTxt">New York, United States</p>
              </div>
            </div>
          </div>
          <div className="testimonyDataBase">
            <div className="testimonyData">
              <p className="testDespTxt">
                "Really good choice of doors. Once we had decided on the style
                we wanted the whole process was very easy. Door was fitted
                earlier than scheduled. Excellent service and installation Would
                recommend Buy your own doors if you’re looking for a new front
                door."
              </p>
              <div>
                <div className="starImgDiv">
                  <img src={Star} alt="star" className="starImg" />
                </div>
                <p className="testNameTxt">Margaret Moffat</p>
                <p className="testLocationTxt">Los Angeles, United States</p>
              </div>
            </div>
          </div>
          <div className="testimonyDataBase">
            <div className="testimonyData">
              <p className="testDespTxt">
                "From the initial visit to the design centre until the final
                installation was a great experience. I was kept updated by Viv
                at all times and the installer was clean and tidy , a real old
                fashioned craftsmen. Good luck and all the best guys."
              </p>
              <div>
                <div className="starImgDiv">
                  <img src={Star} alt="star" className="starImg" />
                </div>
                <p className="testNameTxt">James Bing</p>
                <p className="testLocationTxt">San Francisco, United States</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
