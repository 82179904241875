import React from "react";
import "./TopDoors.scss";
import GLASS from "../../assets/images/home/glass.png"
import DOUBLE from "../../assets/images/home/glass-double.png"
import OPP from "../../assets/images/home/wood-opp.png"
import FIXED from "../../assets/images/home/glass-fixed.png"
import LIGHT from "../../assets/images/home/glass-light.png"

export default function TopDoors() {
  const array = [
    {
      id: 1,
      img: DOUBLE,
      title: "Door Within Door",
    },
    {
      id: 2,
      img: OPP,
      title: "Operable Door",
    },
    {
      id: 3,
      img: FIXED,
      title: "Fixed Door",
    },
    {
      id: 4,
      img: LIGHT,
      title: "Door With Sidelights",
    },
  ];
  return (
    <div className="doorBase">
      <div className="doorContent">
        <div className="doorFlexBase">
          <div className="doorLeftContent">
            <div className="leftDoorImageDiv">
              <img
                className="doorImage"
                src={GLASS}
                alt="door"
              />
            </div>
          </div>
        </div>
        <div className="doorFlexBase">
          <div className="doorRightContent">
            <div className="doorRightTxt">
              <p className="doorTitle">Glass Doors -------</p>
              <p className="doorMainTitle">
                Shop Other Glass Doors To Fit Any Space
              </p>
              <p className="doorMainDesp">
                Please mail us at general.welding.custom@gmail.com and we will
                get back to you regarding your request.
              </p>
            </div>
            <div className="flexDoorSubContent">
              {array.map((data) => (
                <div className="miniDoorData" key={data.id}>
                  <div className="miniContent">
                    <div className="miniImgDiv">
                      <img src={data.img} alt="miniImg" className="miniDoor" />
                    </div>
                    <div className="miniTxtDiv">
                      <p className="miniTxt">{data.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
