import React from "react";
import "./HelpScreen.scss";
import HELP from "../../assets/images/icons/help.png"

export default function HelpScreen() {
  return (
    <div className="helpBase">
      <div className="helpContent">
        <img src={HELP} alt="help" className="helpImg" />
      </div>
    </div>
  );
}
