import React, { useEffect, useMemo, useState } from "react";
import "./ProductScreen.scss";
import { Carousel } from "react-responsive-carousel";
import { HashLink } from "react-router-hash-link";
import Pagination from "../../components/pagination/Pagination";
import { array } from "../../assets/data/product";

export default function ProductScreen() {
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [products, setProducts] = useState(array);
  const [searchVal, setSearchVal] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    id: 0,
    thumb: "",
    type: "",
    amount: "",
    name: "",
    images: [],
  });
  const [type, setType] = useState({
    glassType: false,
    woodType: false,
    metalType: false,
    steelType: false,
    pvcType: false,
  });
  const [category, setCategory] = useState({
    single: false,
    double: false,
  });
  const [handle, setHandle] = useState({
    two: false,
    four: false,
  });
  const [color, setcolor] = useState({
    black: false,
    brown: false,
    transparent: false,
    green: false,
    other: false,
  });

  const filterByCategory = () => {
    var catArray = [];
    var singleVal = "";
    var doubleVal = "";
    if (category.single) {
      singleVal = "single";
    } else if (category.double) {
      doubleVal = "double";
    }
    if (!category.single && !category.double) {
      catArray = array;
      return catArray;
    } else if (!category.single && category.double) {
      array.filter((item) => {
        if (item.category.toLowerCase().includes(doubleVal.toLowerCase())) {
          catArray.push(item);
        }
        return catArray;
      });
    } else if (category.single && !category.double) {
      array.filter((item) => {
        if (item.category.toLowerCase().includes(singleVal.toLowerCase())) {
          catArray.push(item);
        }
        return catArray;
      });
    } else if (category.single && category.double) {
      array.filter((item) => {
        if (
          item.category.toLowerCase().includes(doubleVal.toLowerCase()) ||
          item.category.toLowerCase().includes(singleVal.toLowerCase())
        ) {
          catArray.push(item);
        }
        return catArray;
      });
    }
    return catArray;
  };
  const filterByType = () => {
    var typeArray = [];
    var glassVal = "";
    var woodVal = "";
    var metalVal = "";
    // var steelVal = "";
    // var pvcVal = "";
    if (type.glassType) {
      glassVal = "glass";
    } else if (type.woodType) {
      woodVal = "wood";
    } else if (type.metalType) {
      metalVal = "metal";
    }
    //  else if (type.steelType) {
    //   steelVal = "steel";
    // } else if (type.pvcType) {
    //   pvcVal = "wood";
    // }

    if (
      !type.glassType &&
      !type.woodType &&
      !type.metalType &&
      !type.steelType &&
      !type.pvcType
    ) {
      typeArray = array;
      return typeArray;
    }
    //single sorting
    else if (
      type.glassType &&
      !type.woodType &&
      !type.metalType &&
      !type.steelType &&
      !type.pvcType
    ) {
      array.filter((item) => {
        if (item.type.toLowerCase().includes(glassVal.toLowerCase())) {
          typeArray.push(item);
        }
        return typeArray;
      });
    } else if (
      !type.glassType &&
      type.woodType &&
      !type.metalType &&
      !type.steelType &&
      !type.pvcType
    ) {
      array.filter((item) => {
        if (item.type.toLowerCase().includes(woodVal.toLowerCase())) {
          typeArray.push(item);
        }
        return typeArray;
      });
    } else if (
      !type.glassType &&
      !type.woodType &&
      type.metalType &&
      !type.steelType &&
      !type.pvcType
    ) {
      array.filter((item) => {
        if (item.type.toLowerCase().includes(metalVal.toLowerCase())) {
          typeArray.push(item);
        }
        return typeArray;
      });
    }
    //double sorting
    else if (
      type.glassType &&
      type.woodType &&
      !type.metalType &&
      !type.steelType &&
      !type.pvcType
    ) {
      array.filter((item) => {
        if (
          item.type.toLowerCase().includes(woodVal.toLowerCase()) ||
          item.type.toLowerCase().includes(glassVal.toLowerCase())
        ) {
          typeArray.push(item);
        }
        return typeArray;
      });
    } else if (
      type.glassType &&
      !type.woodType &&
      type.metalType &&
      !type.steelType &&
      !type.pvcType
    ) {
      array.filter((item) => {
        if (
          item.type.toLowerCase().includes(glassVal.toLowerCase()) ||
          item.type.toLowerCase().includes(metalVal.toLowerCase())
        ) {
          typeArray.push(item);
        }
        return typeArray;
      });
    } else if (
      !type.glassType &&
      type.woodType &&
      type.metalType &&
      !type.steelType &&
      !type.pvcType
    ) {
      array.filter((item) => {
        if (
          item.type.toLowerCase().includes(woodVal.toLowerCase()) ||
          item.type.toLowerCase().includes(metalVal.toLowerCase())
        ) {
          typeArray.push(item);
        }
        return typeArray;
      });
    }
    return typeArray;
  };

  const filterBySearch = () => {
    var searchArray = [];
    if (searchVal === "") {
      setProducts(array);
      searchArray = array;
      return array;
    } else {
      array.filter((item) => {
        if (item.type.toLowerCase().includes(searchVal.toLowerCase())) {
          searchArray.push(item);
        }
        return searchArray;
      });
    }
    return searchArray;
  };

  const handleFilter = () => {
    var searchArray = [];
    var categoryArray = [];
    var typeArray = [];

    categoryArray = filterByCategory();
    searchArray = filterBySearch();
    typeArray = filterByType();

    var searchCatArray = categoryArray.filter((elm) => {
      return searchArray.some((ele) => {
        return ele.id === elm.id;
      });
    });

    var secTypeArray = searchCatArray.filter((elm) => {
      return typeArray.some((ele) => {
        return ele.id === elm.id;
      });
    });

    setProducts(secTypeArray);
  };

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal, type, category]);

  const currentTableData = useMemo(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return products.slice(firstPageIndex, lastPageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, products]);

  const [filter, setFilter] = useState(true);
  return (
    <div className="productBase">
      <div className="productContent">
        <div className="productDataDiv">
          <div className="productFilter">
            <div className="filterTitleBase">
              <div className="filterTitle">
                <p className="filterTxt">Fliter</p>
                <button
                  className="filterBtn"
                  onClick={() => setFilter(!filter)}
                >
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/filter.png"
                    alt="filter"
                    className="filterIcon"
                  />
                </button>
              </div>
            </div>
            {filter && (
              <div className="filterSession">
                <div className="hrFilter"></div>
                <div className="sessionTitle">
                  <p className="sessionTitleTxt">Category</p>
                </div>
                <div className="sessionFilter">
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setCategory({
                            ...category,
                            single: e.target.checked,
                          })
                        }
                      />
                      Single Doors
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setCategory({
                            ...category,
                            double: e.target.checked,
                          })
                        }
                      />
                      Double Doors
                    </label>
                  </div>
                </div>
                <div className="hrFilter"></div>
                <div className="sessionTitle">
                  <p className="sessionTitleTxt">Door Types</p>
                </div>
                
                <div className="sessionFilter">
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setType({
                            ...type,
                            glassType: e.target.checked,
                          })
                        }
                      />
                      Glass Doors
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setType({
                            ...type,
                            woodType: e.target.checked,
                          })
                        }
                      />
                      Wooden Doors
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setType({
                            ...type,
                            metalType: e.target.checked,
                          })
                        }
                      />
                      Metal Doors
                    </label>
                  </div>
                  {/* <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setType({
                            ...type,
                            steelType: e.target.checked,
                          })
                        }
                      />
                      Steel Doors
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setType({
                            ...type,
                            pvcType: e.target.checked,
                          })
                        }
                      />
                      PVC
                    </label>
                  </div> */}
                </div>
                {/* <div className="hrFilter"></div>
                <div className="sessionTitle">
                  <p className="sessionTitleTxt">No of Handles</p>
                </div>
                <div className="sessionFilter">
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setHandle({
                            ...handle,
                            two: e.target.checked,
                          })
                        }
                      />
                      Two
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setHandle({
                            ...handle,
                            four: e.target.checked,
                          })
                        }
                      />
                      Four
                    </label>
                  </div>
                </div> */}
                {/* <div className="hrFilter"></div>
                <div className="sessionTitle">
                  <p className="sessionTitleTxt">Colour</p>
                </div>
                <div className="sessionFilter">
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setcolor({
                            ...color,
                            black: e.target.checked,
                          })
                        }
                      />
                      Black
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setcolor({
                            ...color,
                            brown: e.target.checked,
                          })
                        }
                      />
                      Brown
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setcolor({
                            ...color,
                            green: e.target.checked,
                          })
                        }
                      />
                      Green
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setcolor({
                            ...color,
                            transparent: e.target.checked,
                          })
                        }
                      />
                      Transparent
                    </label>
                  </div>
                  <div className="type">
                    <label>
                      <input
                        type="checkbox"
                        className="checkBox"
                        onChange={(e) =>
                          setcolor({
                            ...color,
                            other: e.target.checked,
                          })
                        }
                      />
                      Other
                    </label>
                  </div>
                </div> */}
              </div>
            )}
          </div>
          <div className="productDataList">
            <div className="productSortDiv">
              <input
                type="text"
                placeholder="Search..."
                value={searchVal}
                className="searchTxtBox"
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </div>
            <div className="productListDiv">
              {currentTableData.length === 0 ? (
                <img
                  src="https://www.kpriet.ac.in/asset/frontend/images/nodata.png"
                  alt="not found"
                  style={{ width: "100%" }}
                />
              ) : (
                <>
                  {currentTableData.map((data) => (
                    <div className="contentDataDiv" key={data.id}>
                      <div className="productCardDiv">
                        <div className="cardImgDiv">
                          <img
                            src={data.thumb}
                            alt="data"
                            className="cardImg"
                          />
                        </div>
                        <div className="cardDataDiv">
                          <div className="ailgnCenter">
                            <p className="productTitle">{data.type}</p>
                            <p className="productAmount">{data.amount}</p>
                            <p className="productDesp">{data.name}</p>
                            <button
                              onClick={() => {
                                setShow(!show);
                                window.scrollTo({
                                  top: 0,
                                  left: 0,
                                  behavior: "smooth",
                                });
                                setData({
                                  ...data,
                                  id: data.id,
                                  type: data.type,
                                  amount: data.amount,
                                  thumb: data.thumb,
                                  name: data.name,
                                  images: data.images,
                                });
                              }}
                              className="contactBtn"
                            >
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="pagination">
              <div className="content">
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={products.length}
                  setLastPage={setLastPage}
                  lastPage={lastPage}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
        {show && (
          <div className="productDetailsPopUp">
            <div className="popupCenter">
              <button
                className="closeBtn"
                onClick={() => {
                  setShow(!show);
                }}
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/aimaradoors.pennonn.com/icons/close.png"
                  alt="closeIcn"
                  className="closeIcon"
                />
              </button>
              <div className="caroselDiv">
                <div className="caroselCardDiv">
                  <Carousel
                    autoPlay={true}
                    dynamicHeight={true}
                    infiniteLoop={true}
                    showArrows={true}
                    showStatus={false}
                    showIndicators={true}
                    useKeyboardArrows={true}
                    swipeable={true}
                    emulateTouch={true}
                    thumbWidth={50}
                    interval={3000}
                  >
                    {data.images.map((data) => (
                      <div className="productCardBase" key={data}>
                        <img className="productImg" src={data} alt="img1" />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
              <div className="cardDataDiv">
                <div className="ailgnCenter">
                  <p className="productTitle">
                    {data.type} - ({data.id})
                  </p>
                  <p className="productAmount">{data.amount}</p>
                  <p className="productDesp">{data.name}</p>
                  <div className="detailsDiv">
                    <p className="productDesp">Model</p>
                    <p className="productDesp">:</p>
                    <p className="productDespBlk">{data.model}</p>
                  </div>
                  <div className="detailsDiv">
                    <p className="productDesp">Colour</p>
                    <p className="productDesp">:</p>
                    <p className="productDespBlk">{data.color}</p>
                  </div>
                  <div className="detailsDiv">
                    <p className="productDesp">Category</p>
                    <p className="productDesp">:</p>
                    <p className="productDespBlk">{data.category}</p>
                  </div>
                  <div className="detailsDiv">
                    <p className="productDesp">No of Handles</p>
                    <p className="productDesp">:</p>
                    <p className="productDespBlk">{data.noOfHandles}</p>
                  </div>
                  <button className="contactBtn">
                    <HashLink
                      smooth
                      style={{ textDecoration: "none" }}
                      to="/home#contact"
                    >
                      <p className="navBtnTxt">Contact Us</p>
                    </HashLink>
                  </button>
                  <p className="productDespDown">
                    Our doors are constructed from 12-gauge steel with 5/8″
                    wrought iron for design work, operable double-pane tempered
                    glass that opens independently from the doors, complete
                    insulation with thermal injected foam in the door, window
                    frame, and in the door sheet, sandblasting, spraying zinc,
                    oven-baked oxide primer, black paint and convenient faux
                    finish, and varnish coated for the best anti-rusting.
                  </p>

                  <p className="productDespDown">
                    The steel frame for the doors is pre-hung. The doors’ weight
                    is supported by the steel frame, which also makes
                    installation simple. The frame has mounting flanges that
                    allow lag bolts to be used to fasten it to the framework.
                  </p>
                  <p className="productDespDown">
                    The steel frame for the doors is pre-hung. The doors’ weight
                    is supported by the steel frame, which also makes
                    installation simple. The frame has mounting flanges that
                    allow lag bolts to be used to fasten it to the framework.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
