 import DOOR1 from "../../assets/images/single doors/SD_008.png"
 import DOOR2 from "../../assets/images/single doors/SD_029.png"
 import DOOR3 from "../../assets/images/single doors/SD_002.png"
 import DOOR4 from "../../assets/images/single doors/SD_028.png"
 import DOOR5 from "../../assets/images/single doors/SD_021.png"
 import DOOR6 from "../../assets/images/single doors/SD_003.png"
 import DOOR7 from "../../assets/images/single doors/SD_001.png"
 import DOOR8 from "../../assets/images/single doors/SD_004.png"
 import DOOR9 from "../../assets/images/single doors/SD_030.png"
 import DOOR10 from "../../assets/images/single doors/SD_025.png"
 import DOOR11 from "../../assets/images/single doors/SD_020.png"
 import DOOR12 from "../../assets/images/single doors/SD_014.png"
 import DOOR13 from "../../assets/images/single doors/SD_019.png"
 import DOOR14 from "../../assets/images/single doors/SD_007.png"
 import DOOR15 from "../../assets/images/single doors/SD_005.png"
 import DOOR16 from "../../assets/images/single doors/SD_006.png"
 import DOOR17 from "../../assets/images/single doors/SD_009.png"
 import DOOR18 from "../../assets/images/single doors/SD_018.png"
 import DOOR19 from "../../assets/images/single doors/SD_023.png"
 import DOOR20 from "../../assets/images/single doors/SD_010.png"
 import DOOR21 from "../../assets/images/single doors/SD_026.png"
 import DOOR22 from "../../assets/images/single doors/SD_017.png"
 import DOOR23 from "../../assets/images/single doors/SD_011.png"
 import DOOR24 from "../../assets/images/single doors/SD_013.png"
 import DOOR25 from "../../assets/images/single doors/SD_012.png"
 import DOOR26 from "../../assets/images/single doors/SD_015.png"
 import DOOR27 from "../../assets/images/single doors/SD_016.png"
 import DOOR28 from "../../assets/images/single doors/SD_022.png"
 import DOOR29 from "../../assets/images/single doors/SD_027.png"
 import DOOR30 from "../../assets/images/single doors/SD_031.png"

 



export const array = [
  {
    id: "SD_008",
    thumb:DOOR1,
    type: "Wooden Door",
    amount: "$2,195.00",
    name: "Abbott Model Pre Hung Single Front Entry Wrought Iron Door With Water Cube Glass",
    images: [
      DOOR1,
      DOOR1,
      DOOR1,
      DOOR1
      ],
    color: "Black",
    category: "Single Door",
    model: "Abbott Model",
    noOfHandles: 2,
  },
  {
    id: "SD_029",
    thumb:DOOR2,
    type: "Glass Door",
    amount: "$2,195.00",
    name: "Acton Model Pre Hung Single Front Entry Wrought Iron Door With Clear Low E Glass",
    images: [
      DOOR2,
      DOOR2
    ],
    color: "Black",
    category: "Single Door",
    model: "Acton Model",
    noOfHandles: 2,
  },
  {
    id: "SD_002",
    thumb:DOOR3,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Addison Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass",
    images: [
      DOOR3,
      DOOR3
    ],
    color: "Black",
    category: "Single Door",
    model: "Addison Model",
    noOfHandles: 2,
  },
  {
    id: "SD_028",
    thumb:DOOR4,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Aledo Model Pre Hung Single Front Entry Wrought Iron Door With Frosted Glass",
    images: [
      DOOR4,
      DOOR4
    ],
    color: "Black",
    category: "Single Door",
    model: "Aledo Model",
    noOfHandles: 2,
  },
  {
    id: "SD_021",
    thumb:DOOR5,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Allen Model Pre Hung Single Front Entry Wrought Iron Door With Water Rain Glass Dark Bronze Finish",
    images: [
      DOOR5,
      DOOR5
    ],
    color: "Black",
    category: "Single Door",
    model: "Allen Model",
    noOfHandles: 2,
  },
  {
    id: "SD_003",
    thumb:DOOR6,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Anderson Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass",
    images: [
      DOOR6,
    ],
    color: "Black",
    category: "Single Door",
    model: "Anderson Model",
    noOfHandles: 2,
  },
  {
    id: "SD_001",
    thumb:DOOR7,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Austin Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass",
    images: [
      DOOR7,
    ],
    color: "Black",
    category: "Single Door",
    model: "Austin Model",
    noOfHandles: 2,
  },
  {
    id: "SD_004",
    thumb:DOOR8,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Barry Model Pre Hung Single Front Entry Wrought Iron Door With Water Cube Glass",
    images: [
      DOOR8,
    ],
    color: "Black",
    category: "Single Door",
    model: "Barry Model",
    noOfHandles: 2,
  },
  {
    id: "SD_030",
    thumb:DOOR9,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Bellaire Model Pre Hung Single Front Entry Wrought Iron Door With Frosted Glass Dark Bronze Finish",
    images: [
      DOOR9,
    ],
    color: "Black",
    category: "Single Door",
    model: "Bellaire Model",
    noOfHandles: 2,
  },
  {
    id: "SD_025",
    thumb:DOOR10,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Bellevue Model Pre Hung Single Front Entry Wrought Iron Door With Frosted Glass Dark Bronze Finish",
    images: [
      DOOR10,
    ],
    color: "Black",
    category: "Single Door",
    model: "Bellevue Model",
    noOfHandles: 2,
  },
  {
    id: "SD_020",
    thumb:DOOR11,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Belton Model Pre Hung Single Front Entry Wrought Iron Door With Frosted Glass Dark Bronze Finish",
    images: [
      DOOR11,
    ],
    color: "Black",
    category: "Single Door",
    model: "Belton Model",
    noOfHandles: 2,
  },
  {
    id: "SD_014",
    thumb:DOOR12,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Bruceville Model Pre Hung Single Front Entry Wrought Iron Door With Low E Clear Glass Matte Black Finish",
    images: [
      DOOR12,
    ],
    color: "Black",
    category: "Single Door",
    model: "Bruceville Model",
    noOfHandles: 2,
  },
  {
    id: "SD_019",
    thumb:DOOR13,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Calvert Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass Dark Bronze Finish",
    images: [
      DOOR13,
    ],
    color: "Black",
    category: "Single Door",
    model: "Calvert Model",
    noOfHandles: 2,
  },
  {
    id: "SD_007",
    thumb:DOOR14,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Cameroon Model Pre Hung Single Front Entry Wrought Iron Door With Aqua Lite Glass",
    images: [
      DOOR14,
    ],
    color: "Black",
    category: "Single Door",
    model: "Cameroon Model",
    noOfHandles: 2,
  },
  {
    id: "SD_005",
    thumb:DOOR15,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Canton Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass",
    images: [
      DOOR15,
    ],
    color: "Black",
    category: "Single Door",
    model: "Canton Model",
    noOfHandles: 2,
  },
  {
    id: "SD_006",
    thumb:DOOR16,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Cisco Model Pre Hung Single Front Entry Wrought Iron Door With Water Cube Glass",
    images: [
     DOOR16,
    ],
    color: "Black",
    category: "Single Door",
    model: "Cisco Model",
    noOfHandles: 2,
  },
  {
    id: "SD_009",
    thumb:DOOR17,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Cisco Model Pre Hung Single Front Entry Wrought Iron Door With Water Cube Glass",
    images: [
      DOOR17,
    ],
    color: "Black",
    category: "Single Door",
    model: "Cisco Model",
    noOfHandles: 2,
  },
  {
    id: "SD_018",
    thumb:DOOR18,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Conroe Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass Dark Bronze Finish",
    images: [
     DOOR18,
    ],
    color: "Black",
    category: "Single Door",
    model: "Conroe Model",
    noOfHandles: 2,
  },
  {
    id: "SD_023",
    thumb:DOOR19,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Coppell Pre Hung Single Front Entry Wrought Iron Door With Rain Glass Dark Bronze Finish",
    images: [
      DOOR19,
    ],
    color: "Black",
    category: "Single Door",
    model: "Coppell Model",
    noOfHandles: 2,
  },
  {
    id: "SD_010",
    thumb:DOOR20,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Covington Model Pre Hung Single Front Entry Wrought Iron Door With Low E Clear Glass",
    images: [
      DOOR20,
    ],
    color: "Black",
    category: "Single Door",
    model: "Covington Model",
    noOfHandles: 2,
  },
  {
    id: "SD_026",
    thumb:DOOR21,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Cresson Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass",
    images: [
      DOOR21,
    ],
    color: "Black",
    category: "Single Door",
    model: "Cresson Model",
    noOfHandles: 2,
  },
  {
    id: "SD_017",
    thumb:DOOR22,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Dallas Model Pre Hung Single Front Entry Wrought Iron Door With Low E Clear Glass Matte Black Finish",
    images: [
      DOOR22,
    ],
    color: "Black",
    category: "Single Door",
    model: "Dallas Model",
    noOfHandles: 2,
  },
  {
    id: "SD_011",
    thumb:DOOR23,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Grapevine Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass",
    images: [
      DOOR23,
    ],
    color: "Black",
    category: "Single Door",
    model: "Grapevine Model",
    noOfHandles: 2,
  },
  {
    id: "SD_013",
    thumb:DOOR24,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Hico Model Pre Hung Single Front Entry Wrought Iron Door With Low E Clear Glass Matte Black Finish",
    images: [
      DOOR24,
    ],
    color: "Black",
    category: "Single Door",
    model: "Hico Model",
    noOfHandles: 2,
  },
  {
    id: "SD_012",
    thumb:DOOR25,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Hidalgo Model Pre Hung Single Front Entry Wrought Iron Door With Aqua Lite Glass",
    images: [
      DOOR25,
    ],
    color: "Black",
    category: "Double Door",
    model: "Hidalgo Model",
    noOfHandles: 2,
  },
  {
    id: "SD_015",
    thumb:DOOR26,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Hudson Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass Dark Bronze Finish",
    images: [
      DOOR26,
    ],
    color: "Black",
    category: "Single Door",
    model: "Hudson Model",
    noOfHandles: 2,
  },
  {
    id: "SD_016",
    thumb:DOOR27,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Parker Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass Dark Bronze Finish",
    images: [
      DOOR27,
    ],
    color: "Black",
    category: "Single Door",
    model: "Parker Model",
    noOfHandles: 2,
  },
  {
    id: "SD_022",
    thumb:DOOR28,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Princeton Model Pre Hung Single Front Entry Wrought Iron Door With Rain Glass Dark Bronze Finish",
    images: [
     DOOR28,
    ],
    color: "Black",
    category: "Single Door",
    model: "Princeton Model",
    noOfHandles: 2,
  },
  {
    id: "SD_027",
    thumb: DOOR29,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Reno Model Pre Hung Single Front Entry Wrought Iron Door With Frosted Glass",
    images: [
     DOOR29,
    ],
    color: "Black",
    category: "Single Door",
    model: "Reno Model",
    noOfHandles: 2,
  },
  {
    id: "SD_031",
    thumb:DOOR30,
    type: "Metal Door",
    amount: "$2,195.00",
    name: "Rockport Model Pre Hung Single Front Entry Wrought Iron Door with Matt Black Finish and Reflective Glass",
    images: [
      DOOR30,
    ],
    color: "Black",
    category: "Single Door",
    model: "Rockport Model",
    noOfHandles: 2,
  },
];
