import React from "react";
import CarouselSession from "../../components/carousel/CarouselSession";
import AboutUs from "../../components/aboutUs/AboutUs";
import TopDoors from "../../components/topDoors/TopDoors";
import BottomDoors from "../../components/bottomDoors/BottomDoors";
import GallerySession from "../../components/gallery/GallerySession";
import ContactUs from "../../components/contactUs/ContactUs";
import Testimony from "../../components/testimony/Testimony";
import HelpScreen from "../../components/help/HelpScreen";

export default function HomeScreen() {

  return (
    <div>
      <CarouselSession />
      <div id="about">
        <AboutUs />
      </div>
      <TopDoors />
      <HelpScreen />
      <BottomDoors />

      <div id="gallery">
        <GallerySession />
      </div>
      <div id="contact">
        <ContactUs />
      </div>
      <Testimony />
    </div>
  );
}
