import React from "react";
import "./GallerySession.scss";
import { Link } from "react-router-dom";
import GAL1 from "../../assets/images/gallery/gal1.png"
import GAL2 from "../../assets/images/gallery/gal2.png"
import GAL3 from "../../assets/images/gallery/gal3.png"
import GAL4 from "../../assets/images/gallery/gal4.png"
import GAL5 from "../../assets/images/gallery/gal5.png"
import GAL6 from "../../assets/images/gallery/gal6.png"

export default function GallerySession() {
  const array = [
    {
      id: 1,
      shortTop: true,
      topImg:
        GAL1,
      bottomImg:
        GAL3,
    },
    {
      id: 2,
      shortTop: false,
      topImg:
        GAL2,
      bottomImg:
        GAL5,
    },
    {
      id: 3,
      shortTop: true,
      topImg:
        GAL4,
      bottomImg:
        GAL6,
    },
  ];
  return (
    <div className="galleryBase">
      <div className="galleryContent">
        <div className="galleryTitle">
          <p className="titleTxt">Vist Our Gallery</p>
        </div>
        <div className="imgGalleryRow">
          {array.map((data) => (
            <div className="imgGalleryColumn" key={data.id}>
              {data.shortTop ? (
                <>
                  <div className="shortImgDiv">
                    <img src={data.topImg} alt="data" className="galleryImg" />
                  </div>
                  <div className="longImgDiv">
                    <img
                      src={data.bottomImg}
                      alt="data"
                      className="galleryImg"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="longImgDiv">
                    <img
                      src={data.bottomImg}
                      alt="data"
                      className="galleryImg"
                    />
                  </div>
                  <div className="shortImgDiv">
                    <img src={data.topImg} alt="data" className="galleryImg" />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <div className="buttonGallery">
          <Link to="/product" style={{ textDecoration: "none" }}>
            <button className="blueBtn">Explore Products</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
