import React from "react";
import "./BottomDoors.scss";
import WOOD from "../../assets/images/home/wood.png"
import WOOD1 from "../../assets/images/home/wood-double.png"
import WOOD2 from "../../assets/images/home/wood-opp.png"
import WOOD3 from "../../assets/images/home/wood-fixed.png"
import WOOD4 from "../../assets/images/home/wood-light.png"

export default function BottomDoors() {
  const array = [
    {
      id: 1,
      img: WOOD1,
      title: "Door Within Door",
    },
    {
      id: 2,
      img: WOOD2,
      title: "Operable Door",
    },
    {
      id: 3,
      img: WOOD3,
      title: "Fixed Door",
    },
    {
      id: 4,
      img: WOOD4,
      title: "Door With Sidelights",
    },
  ];
  return (
    <div className="doorBase">
      <div className="doorBottomContent">
        <div className="doorFlexBase">
          <div className="doorBottomRightContent">
            <div className="doorRightTxt">
              <p className="doorTitle">Wooden Doors -------</p>
              <p className="doorMainTitle">
                Shop Other Wooden Doors To Fit Any Space
              </p>
              <p className="doorMainDesp">
                Please mail us at general.welding.custom@gmail.com and we will
                get back to you regarding your request.
              </p>
            </div>
            <div className="flexDoorSubContent">
              {array.map((data) => (
                <div className="miniDoorDataBottom" key={data.id}>
                  <div className="miniContent">
                    <div className="miniImgDiv">
                      <img src={data.img} alt="miniImg" className="miniDoor" />
                    </div>
                    <div className="miniTxtDiv">
                      <p className="miniTxt">{data.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="doorFlexBase">
          <div className="doorLeftContent">
            <div className="leftBottomDoorImageDiv">
              <img
                className="doorImage"
                src={WOOD}
                alt="door"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
