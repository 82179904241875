import React from "react";
import "./NotFound.scss";

export default function NotFound() {
  return (
    <div className="centerNotFOund">
      <img
        src="https://static.vecteezy.com/system/resources/previews/005/883/254/original/page-not-found-404-error-concept-illustration-free-vector.jpg"
        alt="not found"
        className="notFoundImg"
      />
    </div>
  );
}
