import React from "react";
import Header from "../components/header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "../pages/home/HomeScreen";
import GalleryScreen from "../pages/gallery/GalleryScreen";
import NotFound from "../pages/notFound/NotFound";
import ContactScreen from "../pages/contact/ContactScreen";
import Footer from "../components/footer/Footer";
import ProductScreen from "../pages/product/ProductScreen";

export default function Layout() {
  return (
    <div className="layoutBase">
      <BrowserRouter>
        <div className="top">
          <Header />
        </div>
        <div className="data">
          <Routes>
            <Route path="/">
              <Route index element={<HomeScreen />} />
              <Route path="home" element={<HomeScreen />} />
              <Route path="gallery" element={<GalleryScreen />} />
              <Route path="product" element={<ProductScreen />} />
              <Route path="contact-us" element={<ContactScreen />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
        <div className="top">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}
