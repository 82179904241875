import React from "react";
import { usePagination, DOTS } from "../../utils/pagination/UsePagination";
import "./Pagination.scss";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className="pagination-container">
      {currentPage !== 1 && (
        <li className="pagination-item-btn" onClick={onPrevious}>
          &lt;
        </li>
      )}
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item-dot dots">&#8230;</li>;
        }

        return (
          <li
            className="pagination-item"
            style={{
              backgroundColor: currentPage === pageNumber ? "#fff" : "#333580",
              color: currentPage === pageNumber ? "#333580" : "#fff",
            }}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {currentPage !== lastPage && (
        <li className="pagination-item-btn" onClick={onNext}>
          &gt;
        </li>
      )}
    </ul>
  );
};

export default Pagination;
