import IMG1 from "../images/carousel/glass.png"
import IMG2 from "../images/carousel/steel.png"
import IMG3 from "../images/carousel/pvc.png"
import IMG4 from "../images/carousel/wood.png"
export const array = [
  {
    id: 1,
    img: IMG1,
    title: "Custom Designed Glass Windows & Doors",
  },
  {
    id: 2,
    img: IMG2,
    title: "Custom Designed Metal Windows & Doors",
  },
  {
    id: 3,
    img: IMG3,
    title: "Custom Designed PVC Windows & Doors",
  },
  {
    id: 4,
    img: IMG4,
    title: "Custom Designed Wood Windows & Doors",
  },
];
