import React, { useState } from "react";
import "./ContactUs.scss";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import * as Yup from "yup";
import MAIL from "../../assets/images/icons/mail.png"
import CONTACT from "../../assets/images/icons/contact.png"
import PHONE from "../../assets/images/icons/phone.png"
import AT from "../../assets/images/icons/@.png"

export default function ContactUs() {
  const validationSchema = Yup.object({
    name: Yup.string().required("* Your Name is required"),
    company: Yup.string().required("* Company name is required"),
    email: Yup.string().required("* Email Address is required"),
    phone: Yup.string().required("* Phone Number is required"),
    message: Yup.string().required("* Message is required"),
  });

  const [contactForm] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });

  function objectToFormData(obj) {
    const formData = new FormData();

    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return formData;
  }
  const onSubmit = (values, submitProps) => {
    console.log("values", values);
    const data = {
      Name: values.name,
      Email: values.email,
      Phone: values.phone,
      Company: values.company,
      Message: values.message,
    };
    console.log(data);
    const fData = objectToFormData(data);
    console.log(fData);
    fetch(
      "https://script.google.com/macros/s/AKfycbz6wrC8fz2rVlkiBZLrYg3WJHXiJlaM1066K1UdZmkKCz5f2D0EGp2GWdXE5J-KNsLsKA/exec",
      {
        method: "POST",
        body: fData,
      }
    );
    submitProps.resetForm();
  };

  const MyTextArea = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <textarea className="textArea" {...field} {...props} />
      </>
    );
  };

  return (
    <div className="contactUsBase">
      <div className="contactUsContent">
        <div className="contactLeft">
          <Formik
            initialValues={contactForm}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form className="form">
                  <div className="formTop">
                    <div className="formTItleDiv">
                      <p className="formTitle">Send Us A Message</p>
                      <div className="hrForm"></div>
                    </div>
                    <div className="formImg">
                      <img
                        src={MAIL}
                        alt="formMail"
                        className="formMailImg"
                      />
                    </div>
                  </div>
                  <div className="formContent">
                    <div className="formContentTop">
                      <div className="field_area">
                        <label htmlFor="name" className="lableText">
                          Your Name
                        </label>
                        <div>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="textField"
                          />
                        </div>
                        <ErrorMessage name="name">
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="field_area">
                        <label htmlFor="company" className="lableText">
                          Company
                        </label>
                        <div>
                          <Field
                            type="text"
                            id="company"
                            name="company"
                            className="textField"
                          />
                        </div>
                        <ErrorMessage name="company">
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="formContentTop">
                      <div className="field_area">
                        <label htmlFor="email" className="lableText">
                          Email Address
                        </label>
                        <div>
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            className="textField"
                          />
                        </div>
                        <ErrorMessage name="email">
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="field_area">
                        <label htmlFor="phone" className="lableText">
                          Phone
                        </label>
                        <div>
                          <Field
                            type="text"
                            id="phone"
                            name="phone"
                            className="textField"
                          />
                        </div>
                        <ErrorMessage name="phone">
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="formContentBottom">
                      <div className="field_area">
                        <label htmlFor="message" className="lableText">
                          Message
                        </label>
                        <div>
                          <MyTextArea name="message" rows="3" />
                        </div>
                        <ErrorMessage name="message">
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="formBtnDiv">
                        <button type="submit" className="submitBtn">
                          <img
                            src="https://flaticons.net/icon.php?slug_category=miscellaneous&slug_icon=paper-plane"
                            className="submitIcon"
                            alt="send"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="contactRight">
          <div className="formTop">
            <div className="formTItleDiv">
              <p className="formTitleRight">Contact Info</p>
              <div className="hrForm"></div>
            </div>
            <div className="formImg">
              <img
                src={CONTACT}
                alt="formMail"
                className="formInfoImg"
              />
            </div>
          </div>
          <div className="rightContentDiv">
            <p className="titleTxtSub">Texas</p>
            <div className="subHrRight"></div>
            <div className="hrRight"></div>
            <div className="contactInfo">
              <div className="contactDataImg">
                <img
                  src={PHONE}
                  alt="mail"
                  className="contactIcon"
                />
              </div>
              <div className="contactDataTxt">
                <p className="contactTxt">
                  Tel :{" "}
                  <a
                    href="tel:+1 (832) 908-5706"
                    style={{ textDecoration: "none" }}
                  >
                    <span className="contactTxt">+1 (832) 908-5706</span>
                  </a>
                </p>
                <p className="contactTxt">Fax : New York Page</p>
              </div>
            </div>
            <div className="contactInfo">
              <div className="contactDataImg">
                <img
                  src={AT}
                  alt="mail"
                  className="contactIcon"
                />
              </div>
              <div className="contactDataTxt">
                <p className="contactTxt">info@aimaradoors.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
