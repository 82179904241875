import React from "react";
import "./AboutUs.scss";
import { HashLink } from "react-router-hash-link";
import ABOUT from "../../assets/images/home/about.png"

export default function AboutUs() {
  return (
    <div className="aboutBase">
      <div className="aboutContent">
        <div className="aboutFlexBase">
          <div className="leftContent">
            <p className="aboutTitle">About Us</p>
            <p className="aboutMainTitle">
              Minimal or Luxurious - Why Not Both?
            </p>
            <p className="aboutMainDesp">
              There are several new trends in exterior door designs that are
              gaining popularity. One trend is the use of bold colors, such as
              bright red or navy blue, to make a statement and add personality
              to the home’s exterior. Another trend is the use of glass panels
              or sidelights to allow more natural light into the home and create
              a more open feel.
            </p>
            <p className="aboutMainDesp">
              Additionally, there is a growing interest in eco-friendly and
              sustainable materials, such as reclaimed wood or bamboo, for
              exterior doors. Finally, there is a trend towards more modern and
              minimalist designs, with clean lines and simple shapes. Whatever
              your style preferences may be, there are many exciting new options
              available in exterior door designs.
            </p>
            <HashLink
              smooth
              style={{ textDecoration: "none" }}
              to="/home#gallery"
            >
              <button className="blueBtn">View Gallery</button>
            </HashLink>
          </div>
        </div>
        <div className="aboutFlexBase">
          <div className="rightContent">
            <div className="aboutimgDiv">
              <div className="imageBorderDiv"></div>
              <img
                className="aboutImage"
                src={ABOUT}
                alt="aboutImg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
