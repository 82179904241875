import React from "react";
import "./Footer.scss";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import LOGOW from "../../assets/images/icons/logo-white.png"
import FACEBOOK from "../../assets/images/icons/fb.png"
import INSTA from "../../assets/images/icons/in.png"
import TWITTER from "../../assets/images/icons/twitter.png"
import LINKEDIN from "../../assets/images/icons/insta.png"

export default function Footer() {
  return (
    <div className="footerBase">
      <div className="footerContent">
        <div className="footerTop">
          <div className="footerGrid">
            <div className="logoFooter">
              <Link
                to="/home"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                style={{ textDecoration: "none" }}
              >
                <img
                  src={LOGOW}
                  alt="logo"
                  className="logoImg"
                />
              </Link>
            </div>
            <p className="footerDesp">
              Please mail us at general.welding.custom@gmail.com and we will get
              back to you regarding your request.
            </p>
            <div className="footerIcons">
              <a href="https://www.facebook.com/">
                <img
                  src={FACEBOOK}
                  alt="logo"
                  className="socialIcon"
                />
              </a>
              <a href="https://www.linkedin.com/">
                <img
                  src={LINKEDIN}
                  alt="logo"
                  className="socialIcon"
                />
              </a>
              <a href="https://twitter.com/">
                <img
                  src={TWITTER}
                  alt="logo"
                  className="socialIcon"
                />
              </a>
              <a href="https://www.instagram.com/">
                <img
                  src={INSTA}
                  alt="logo"
                  className="socialIcon"
                />
              </a>
            </div>
          </div>
          <div className="footerGrid">
            <p className="footerHeadTxt">Quick Links</p>
            <Link to="/home" style={{ textDecoration: "none" }}>
              <p className="footerLinkTxt">Home</p>
            </Link>
            <Link to="/product" style={{ textDecoration: "none" }}>
              <p className="footerLinkTxt">Products</p>
            </Link>
            <HashLink
              smooth
              style={{ textDecoration: "none" }}
              to="/home#gallery"
            >
              <p className="footerLinkTxt">Photo Gallery</p>
            </HashLink>
            <HashLink
              smooth
              style={{ textDecoration: "none" }}
              to="/home#contact"
            >
              <p className="footerLinkTxt">Contact</p>
            </HashLink>
          </div>
          <div className="footerGrid">
            <p className="footerHeadTxt">About Us</p>
            <p className="footerLinkTxt">Minimal & Luxurious</p>
            <p className="footerLinkTxt">To Fit Any Space</p>
            <p className="footerLinkTxt">Easy to get started</p>
            <p className="footerLinkTxt">Expolore the wide variety</p>
          </div>
          <div className="footerGrid">
            <p className="footerHeadTxt">Contact Info</p>
            <a href="tel:+1 (832) 908-5706" style={{ textDecoration: "none" }}>
              <p className="footerLinkTxt">+1 (832) 908-5706</p>
            </a>
            <p className="footerLinkTxt">info@aimaradoors.com</p>
          </div>
        </div>
        <div className="footerLine"></div>
        <div className="footerBottomDiv">
          <p className="copyTxt">copyright &copy; 2023 AimaraDoors</p>
          <a
            href="https://pennonn.com/"
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <p className="copyTxt">Powered By: Pennonn Inc</p>
          </a>
        </div>
      </div>
    </div>
  );
}
